<div>
  <app-page-header
    [title]="
      activatedRoute.snapshot.paramMap.get('id')
        ? 'Modifica template licenza'
        : 'Crea nuovo template licenza'
    "
    [showBack]="authService.user.profile.name !== 'CUSTOMER'"
    [breadcrumb]="['Template Licenze']"
  >
    <div class="d-flex">
      <button
        mat-stroked-button
        color="primary"
        class="mr-3"
        (click)="onBack()"
      >
        Annulla
      </button>
      <button
        mat-flat-button
        [disabled]="!form.valid"
        color="primary"
        (click)="save()"
        *ngIf="authService.hasRole('LICENSE_TEMPLATE_CAN_WRITE')"
      >
        Salva
      </button>
    </div>
  </app-page-header>

  <div class="container-fluid py-3">
    <form #form="ngForm">
      <div class="w-75 m-auto pt-4">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center text-primary">
            <mat-icon class="mr-2">assignment</mat-icon>
            Definizione template licenza
          </mat-card-title>
          <mat-card-content>
            <mat-form-field class="w-100">
              <mat-label>Nome Licenza</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="name"
                placeholder="Inserisci nome"
                [(ngModel)]="licenseTemplate.name"
                required
                #nameModel="ngModel"
              />
              <mat-hint align="end"
                >{{ licenseTemplate?.name?.length || 0 }}/255</mat-hint
              >

              <mat-error
                appFormFieldError
                [formFieldModel]="nameModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Descrizione Licenza</mat-label>
              <textarea
                matInput
                name="description"
                placeholder="Inserisci descrizione"
                [(ngModel)]="licenseTemplate.description"
                required
                maxlength="255"
                validateMaxLength
                [validateMaxLengthValue]="255"
                #descriptionModel="ngModel"
              ></textarea>
              <mat-hint align="end"
                >{{ licenseTemplate?.description?.length || 0 }}/255</mat-hint
              >

              <mat-error
                appFormFieldError
                [formFieldModel]="descriptionModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="w-75 m-auto pt-4">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center text-primary">
            <mat-icon class="mr-2">assignment</mat-icon>
            Configurazione Policy
          </mat-card-title>
          <mat-card-content>
            <app-policy-management
              name="policies"
              [topForm]="form"
              [(ngModel)]="licenseTemplate.policies"
              required
            ></app-policy-management>

            <div class="mt-3">
              <app-alert
                *ngIf="form.submitted && !licenseTemplate?.policies.length"
                type="error"
                text="Aggiungere almeno una policy"
              >
              </app-alert>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </form>
  </div>
</div>
