import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from 'app/armp/core/error.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    standalone: false
})
export class ErrorComponent implements OnInit {
  constructor(
    private errorService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private location: Location
  ) {
    if (this.activatedRoute.snapshot.params.type === '404') {
      this.errorService.errors = [new Error(this.translateService.instant('ERROR.PAGE_NOT_FOUND'))];
    }
  }

  ngOnInit() {}

  onBack() {
    this.location.back();
  }

  get errors() {
    return this.errorService.errors;
  }
}
