<app-page-header title="Template Licenza" [showBack]="false">
  <div class="d-flex align-items-center">
    <button
      class="mr-4"
      mat-flat-button
      color="primary"
      [routerLink]="['new']"
      *ngIf="authService.hasRole('LICENSE_TEMPLATE_CAN_WRITE')"
    >
      Crea Template Licenza
    </button>

    <bitf-search
      (valueChanges)="searchText = $event"
      placeholder="Template licenza"
      label="Cerca template licenza"
      [isInToolbar]="true"
      [hasSearchIcon]="false"
    >
    </bitf-search>
  </div>
</app-page-header>

<div class="container-fluid py-3">
  <app-dynamic-table
    [service]="licenseTemplateService"
    [columns]="dataColumns"
    [showDeleteBtn]="authService.hasRole('LICENSE_TEMPLATE_CAN_DELETE')"
    [searchText]="searchText"
  ></app-dynamic-table>

  <ng-template #dateTimeTpl let-value="value">
    {{ value | date : "short" }}
  </ng-template>
</div>
