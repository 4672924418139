<app-page-header
  [title]="
    activatedRoute.snapshot.paramMap.get('id')
      ? 'Modifica utente'
      : 'Crea nuovo utente'
  "
  [breadcrumb]="['Utenti']"
>
  <div class="d-flex">
    <button mat-stroked-button color="primary" class="mr-3" (click)="onBack()">
      Annulla
    </button>
    <button
      mat-flat-button
      [disabled]="!form.valid"
      color="primary"
      (click)="save()"
      *ngIf="authService.hasRole('USER_CAN_WRITE')"
    >
      Salva
    </button>
  </div>
</app-page-header>

<div class="container-fluid py-3">
  <form #form="ngForm" novalidate>
    <div class="w-75 m-auto">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex align-items-center text-primary">
          <mat-icon class="mr-2">assignment_ind</mat-icon>
          Definizione Utente
        </mat-card-title>

        <mat-card-content>
          <mat-form-field class="w-100">
            <mat-label>Username</mat-label>
            <input
              matInput
              type="text"
              maxlength="255"
              name="username"
              placeholder="Inserisci username"
              [(ngModel)]="entity.username"
              required
              #usernameModel="ngModel"
            />
            <mat-hint align="end"
              >{{ entity?.username?.length || 0 }}/255</mat-hint
            >
            <mat-error
              appFormFieldError
              [formFieldModel]="usernameModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label>Dominio</mat-label>
            <input
              matInput
              type="text"
              maxlength="255"
              name="domain"
              placeholder="Inserisci dominio"
              [(ngModel)]="entity.domain"
              #domainModel="ngModel"
            />
            <mat-error
              appFormFieldError
              [formFieldModel]="domainModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label>Profilo utente</mat-label>
            <mat-select
              #profileIdModel="ngModel"
              name="profileId"
              class="form-control off-bxshadow"
              [(ngModel)]="entity.profileId"
              (selectionChange)="showCustomers(entity.profileId)"
              required
            >
              <mat-option [value]="undefined"
                >Seleziona il profilo utente</mat-option
              >
              <mat-option
                *ngFor="let profileId of profileIds"
                [value]="profileId.id"
              >
                {{ profileId.name }}
              </mat-option>
            </mat-select>
            <mat-error
              appFormFieldError
              [formFieldModel]="profileIdModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100" *ngIf="isCliente">
            <mat-label>Cliente Associato</mat-label>
            <mat-select
              #customerIdModel="ngModel"
              name="customerId"
              class="form-control off-bxshadow"
              [(ngModel)]="entity.customerId"
              [required]="isCliente"
            >
              <mat-option [value]="undefined"
                >Seleziona il cliente associato</mat-option
              >
              <mat-option
                *ngFor="let customer of customers"
                [value]="customer.id"
                >{{ customer.name }}</mat-option
              >
            </mat-select>
            <mat-error
              appFormFieldError
              [formFieldModel]="customerIdModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label>Nome</mat-label>
            <input
              matInput
              type="text"
              maxlength="255"
              name="displayName"
              placeholder="Inserisci nome"
              [(ngModel)]="entity.displayName"
              [required]="entity.local"
              #displayNameModel="ngModel"
            />
            <mat-hint align="end"
              >{{ entity?.displayName?.length || 0 }}/255</mat-hint
            >
            <mat-error
              appFormFieldError
              [formFieldModel]="displayNameModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>
