import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';

import { AuthService } from 'app/armp/core/auth.service';

@Component({
    selector: 'app-armp',
    templateUrl: './armp.component.html',
    styleUrls: ['./armp.component.scss'],
    standalone: false
})
export class ArmpComponent implements OnInit {
  classes: string[];
  showSidenav = false;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    public authService: AuthService,
    private router: Router
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationError
      ) {
        this.classes = this.getClasses();
        this.showSidenav =
          this.authService.isLoggedIn &&
          !this.classes.includes('route-error') &&
          !this.classes.includes('route-public') &&
          authService.user?.profile.name !== 'CUSTOMER';
      }
    });
  }

  ngOnInit() {}

  getClasses(): string[] {
    const classes = [];
    if (this.authService.isLoggedIn) {
      classes.push('logged-in');
      if (this.authService.user.profile.name === 'CUSTOMER') {
        classes.push('route-public');
      }
    } else {
      classes.push('not-logged');
    }

    let url = this.router.url.split('?')[0];
    if (url.startsWith('/')) {
      url = url.substring(1);
    }

    url = url.split('/')[0];

    if (url.length === 0) {
      url = 'home';
    }
    classes.push('route-' + url.replace('/', '-'));
    return classes;
  }
}
