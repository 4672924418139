<app-page-header
  [title]="
    activatedRoute.snapshot.paramMap.get('id')
      ? 'Modifica Tipo Informazione'
      : 'Crea nuovo Tipo Informazione'
  "
  [breadcrumb]="['Tipi Informazione']"
>
  <div class="d-flex">
    <button mat-stroked-button color="primary" class="mr-3" (click)="onBack()">
      Annulla
    </button>
    <button
      mat-flat-button
      [disabled]="!form.valid"
      color="primary"
      (click)="save()"
      *ngIf="authService.hasRole('INFORMATION_TYPE_CAN_WRITE')"
    >
      Salva
    </button>
  </div>
</app-page-header>

<div class="container-fluid py-3">
  <form #form="ngForm" novalidate>
    <div class="w-75 m-auto">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex align-items-center text-primary">
          <mat-icon class="mr-2">assignment_ind</mat-icon>
          Definizione Tipo Informazione
        </mat-card-title>
        <mat-card-content>
          <mat-form-field class="w-100">
            <mat-label>Codice</mat-label>
            <input
              matInput
              type="text"
              maxlength="255"
              name="code"
              placeholder="Inserisci codice"
              [(ngModel)]="entity.code"
              required
              [readonly]="!!entity.id"
              #codeModel="ngModel"
            />
            <mat-error
              appFormFieldError
              [formFieldModel]="codeModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label>Nome</mat-label>
            <input
              matInput
              type="text"
              maxlength="255"
              name="name"
              placeholder="Inserisci nome"
              [(ngModel)]="entity.name"
              required
              #nameModel="ngModel"
            />
            <mat-error
              appFormFieldError
              [formFieldModel]="nameModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>

          <div class="mt-4 d-flex flex-column">
            <app-file-uploader
              [showFileList]="false"
              [showDndArea]="true"
              [maxNumberOfFiles]="1"
              [showValidationErrorMessage]="true"
              [concatenateMultipleFiles]="false"
              [uploadOnFilesDropped]="true"
              [uploadButtonText]="'Attiva istanza'"
              (startUpload)="onStartUploadFile($event)"
            >
            </app-file-uploader>

            <div class="w-100 position-relative mt-2 codemirror">
              <ngx-codemirror
                [(ngModel)]="entity.defaultXsl"
                name="defaultXsl"
                required
                [options]="{
                  lineNumbers: true,
                  theme: 'material',
                  mode: 'xml',
                  lines: '100'
                }"
              ></ngx-codemirror>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>
