<div>
  <app-page-header title="Crea nuovo cliente" [breadcrumb]="['Clienti']">
    <div class="d-flex">
      <button
        mat-stroked-button
        color="primary"
        class="mr-3"
        (click)="onBack()"
      >
        Annulla
      </button>
      <button
        mat-flat-button
        [disabled]="!form.valid"
        color="primary"
        (click)="save()"
        *ngIf="authService.hasRole('CUSTOMER_CAN_WRITE')"
      >
        Salva
      </button>
    </div>
  </app-page-header>

  <div class="container-fluid py-3">
    <form #form="ngForm" novalidate>
      <div class="w-75 m-auto">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center text-primary">
            <mat-icon class="mr-2">assignment_ind</mat-icon>
            Definizione Cliente
          </mat-card-title>

          <mat-card-content>
            <mat-form-field class="w-100">
              <mat-label>Nome</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="name"
                placeholder="Inserisci nome"
                [(ngModel)]="entity.name"
                required
                #nameModel="ngModel"
              />
              <mat-hint align="end"
                >{{ entity?.name?.length || 0 }}/255</mat-hint
              >
              <mat-error
                appFormFieldError
                [formFieldModel]="nameModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Email</mat-label>
              <input
                matInput
                type="text"
                name="email"
                placeholder="Inserisci email"
                [(ngModel)]="entity.email"
                required
                validateEmail
                #emailModel="ngModel"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="emailModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-checkbox
              class="mt-3"
              name="organization"
              [(ngModel)]="entity.organization"
              >Organizzazione?</mat-checkbox
            >

            <mat-form-field class="w-100" *ngIf="entity.organization">
              <mat-label>Partita IVA</mat-label>
              <input
                matInput
                type="text"
                name="vatNumber"
                placeholder="Inserisci Partiva IVA"
                [(ngModel)]="entity.vatNumber"
                required
                validateVatCode
                #vatCodeModel="ngModel"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="vatCodeModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100" *ngIf="!entity.organization">
              <mat-label>Codice Fiscale</mat-label>
              <input
                matInput
                type="text"
                name="fiscalCode"
                placeholder="Inserisci Codice Fiscale"
                [(ngModel)]="entity.fiscalCode"
                required
                validateFiscalCode
                #fiscalCodeModel="ngModel"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="fiscalCodeModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Indirizzo</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="address"
                placeholder="Inserisci indirizzo"
                [(ngModel)]="entity.address"
                required
                #addressModel="ngModel"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="addressModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Città</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="city"
                placeholder="Inserisci città"
                [(ngModel)]="entity.city"
                required
                #cityModel="ngModel"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="cityModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Provincia</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="state"
                placeholder="Inserisci provincia"
                [(ngModel)]="entity.state"
                required
                #stateModel="ngModel"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="stateModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Nazione</mat-label>
              <mat-select
                #countryIdModel="ngModel"
                name="countryId"
                [(ngModel)]="entity.countryId"
                required
              >
                <mat-option [value]="undefined"
                  >Seleziona la nazione</mat-option
                >
                <mat-option
                  [value]="country.id"
                  *ngFor="let country of countries"
                  >{{ country.name }}</mat-option
                >
              </mat-select>
              <mat-error
                appFormFieldError
                [formFieldModel]="countryIdModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="w-75 m-auto pt-4">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center text-primary">
            <mat-icon class="mr-2">assignment</mat-icon>
            Sistema Esterno
          </mat-card-title>
          <mat-card-content>
            <mat-form-field class="w-100">
              <mat-label>Sistema</mat-label>
              <mat-select
                #systemIdModel="ngModel"
                name="systemId"
                [(ngModel)]="entity.systemId"
              >
                <mat-option [value]="undefined"
                  >Seleziona il sistema</mat-option
                >
                <mat-option
                  [value]="system.id"
                  *ngFor="let system of systems"
                  >{{ system.name }}</mat-option
                >
              </mat-select>
              <mat-error
                appFormFieldError
                [formFieldModel]="systemIdModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>ID</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="extId"
                placeholder="Inserisci id sistema esterno"
                [(ngModel)]="entity.extId"
                [required]="entity.systemId"
                #extIdModel="ngModel"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="extIdModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="w-75 m-auto pt-4" *ngIf="entity.organization">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center text-primary">
            <mat-icon class="mr-2">assignment_ind</mat-icon>
            Referente
          </mat-card-title>
          <mat-card-content>
            <mat-form-field class="w-100">
              <mat-label>Nome</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="firstName"
                placeholder="Inserisci Nome Referente"
                [(ngModel)]="entity.firstName"
                required
                #firstNameModel="ngModel"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="firstNameModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Cognome</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="lastName"
                placeholder="Inserisci Cognome Referente"
                [(ngModel)]="entity.lastName"
                required
                #lastNameModel="ngModel"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="lastNameModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="w-75 m-auto pt-4">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center text-primary">
            <mat-icon class="mr-2">vpn_key</mat-icon>
            Certificati Associati
          </mat-card-title>
          <mat-card-content>
            <app-certificate-management
              [(ngModel)]="certificates"
              name="certificates"
              [canManageCertificates]="
                authService.hasRole('CUSTOMER_CAN_WRITE')
              "
            >
            </app-certificate-management>
          </mat-card-content>
        </mat-card>
      </div>
    </form>
  </div>
</div>
