import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { CustomerService } from 'app/armp/services/customer.service';
import { SystemService } from 'app/armp/services/system.service';
import { CountryService } from 'app/armp/services/country.service';
import { AuthService } from 'app/armp/core/auth.service';
import { ToastService } from 'app/armp/services/toast.service';
import { LoaderService } from 'app/armp/core/loader.service';

@Component({
    selector: 'app-customer-create',
    templateUrl: './customer-create.component.html',
    styleUrls: ['./customer-create.component.scss'],
    standalone: false
})
export class CustomerCreateComponent implements OnInit {
  @ViewChild('form', { static: false }) private form: NgForm;

  entity;
  certificates = [];
  systems = [];
  countries = [];

  private sub: any;
  constructor(
    public authService: AuthService,
    private customerService: CustomerService,
    private systemService: SystemService,
    private countryService: CountryService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private location: Location
  ) {}

  ngOnInit() {
    this.entity = {};
    this.systemService.getAll().then((systems) => (this.systems = systems));
    this.countryService
      .getAll()
      .then((countries) => (this.countries = countries));
  }

  onBack() {
    this.location.back();
  }

  save() {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.loaderService.show();

      this.entity.certificates = this.certificates.map((x) => {
        const tmp = {
          id: null,
        };
        Object.assign(tmp, x);
        return tmp;
      });

      this.customerService
        .save(this.entity)
        .then((x) => {
          this.loaderService.hide();
          this.toastService.success({
            message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
          });
          this.onBack();
        })
        .catch(() => {
          this.loaderService.hide();
        });
    }
  }
}
