import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { UserService } from 'app/armp/services/user.service';
import { User } from 'app/armp/models/user';
import { ProfileService } from 'app/armp/services/profile.service';
import { CustomerService } from 'app/armp/services/customer.service';
import { AuthService } from 'app/armp/core/auth.service';
import { LoaderService } from 'app/armp/core/loader.service';
import { ToastService } from 'app/armp/services/toast.service';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss'],
    standalone: false
})
export class UserEditComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: false }) private form!: NgForm;

  entity!: User;
  profileIds = [];
  customers = [];
  isCliente = false;

  private subscription = new Subscription();

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private customerservice: CustomerService,
    private location: Location,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private translateService: TranslateService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.entity = new User();
    this.entity.local = false;
    this.subscription = this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.userService.getById(+params.id).then((p) => {
          this.entity = p;

          if (this.entity.customerId != null) {
            this.isCliente = true;
          }
        });
      }
    });

    this.profileService.getAll().then((response) => {
      this.profileIds = response;
    });

    this.customerservice.getAll().then((response) => {
      this.customers = response;
    });
  }

  showCustomers(profileId) {
    this.profileService.getById(profileId).then((x) => {
      this.isCliente = x.name === 'CUSTOMER';
    });
  }

  onBack() {
    this.location.back();
  }

  save() {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.loaderService.show();

      this.userService
        .save(this.entity)
        .then(() => {
          this.loaderService.hide();
          this.toastService.success({
            message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
          });
          this.onBack();
        })
        .catch(() => {
          this.loaderService.hide();
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
