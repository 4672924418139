import { Component, OnInit } from '@angular/core';

import { RoleService } from 'app/armp/services/role.service';
import { AuthService } from 'app/armp/core/auth.service';

@Component({
    selector: 'app-role-list',
    templateUrl: './role-list.component.html',
    styleUrls: ['./role-list.component.scss'],
    standalone: false
})
export class RoleListComponent implements OnInit {
  dataColumns: any[] = [];
  searchText: string;

  constructor(
    public roleService: RoleService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataColumns.push(
      {
        name: 'Nome',
        prop: 'name',
        sortable: true,
      },
      {
        name: 'Description',
        prop: 'description',
        sortable: true,
      }
    );
  }
}
