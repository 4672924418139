<mat-sidenav-container class="h-100">
  <mat-sidenav mode="side"
    [opened]="authService.isLoggedIn"
    *ngIf="authService.isLoggedIn && showSidenav">
    <app-sidebar *ngIf="showSidenav"></app-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="position-relative">
    <app-navbar></app-navbar>

    <div class="flex-grow-1 app-router-content">
      <router-outlet></router-outlet>
    </div>
    <!-- // NOTE needed to make work a single chip -->
    <mat-chip-listbox></mat-chip-listbox>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-loader></app-loader>