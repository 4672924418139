import * as i0 from '@angular/core';
import { EventEmitter, forwardRef, Component, ChangeDetectionStrategy, Input, Output, ViewChild, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
const _c0 = ["ref"];
function normalizeLineEndings(str) {
  if (!str) {
    return str;
  }
  return str.replace(/\r\n|\r/g, '\n');
}
class CodemirrorComponent {
  constructor(_differs, _ngZone) {
    this._differs = _differs;
    this._ngZone = _ngZone;
    /* class applied to the created textarea */
    this.className = '';
    /* name applied to the created textarea */
    this.name = 'codemirror';
    /* autofocus setting applied to the created textarea */
    this.autoFocus = false;
    /* preserve previous scroll position after updating value */
    this.preserveScrollPosition = false;
    /* called when the text cursor is moved */
    this.cursorActivity = new EventEmitter();
    /* called when the editor is focused or loses focus */
    this.focusChange = new EventEmitter();
    /* called when the editor is scrolled */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.scroll = new EventEmitter();
    /* called when file(s) are dropped */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.drop = new EventEmitter();
    this.value = '';
    this.disabled = false;
    this.isFocused = false;
    /** Implemented as part of ControlValueAccessor. */
    this.onChange = _ => {};
    /** Implemented as part of ControlValueAccessor. */
    this.onTouched = () => {};
  }
  /**
   * set options for codemirror
   * @link http://codemirror.net/doc/manual.html#config
   */
  set options(value) {
    this._options = value;
    if (!this._differ && value) {
      this._differ = this._differs.find(value).create();
    }
  }
  get codeMirrorGlobal() {
    if (this._codeMirror) {
      return this._codeMirror;
    }
    // in order to allow for universal rendering, we import Codemirror runtime with `require` to prevent node errors
    this._codeMirror = typeof CodeMirror !== 'undefined' ? CodeMirror : import('codemirror');
    return this._codeMirror;
  }
  ngAfterViewInit() {
    this._ngZone.runOutsideAngular(async () => {
      const codeMirrorObj = await this.codeMirrorGlobal;
      const codeMirror = codeMirrorObj?.default ? codeMirrorObj.default : codeMirrorObj;
      this.codeMirror = codeMirror.fromTextArea(this.ref.nativeElement, this._options);
      this.codeMirror.on('cursorActivity', cm => this._ngZone.run(() => this.cursorActive(cm)));
      this.codeMirror.on('scroll', this.scrollChanged.bind(this));
      this.codeMirror.on('blur', () => this._ngZone.run(() => this.focusChanged(false)));
      this.codeMirror.on('focus', () => this._ngZone.run(() => this.focusChanged(true)));
      this.codeMirror.on('change', (cm, change) => this._ngZone.run(() => this.codemirrorValueChanged(cm, change)));
      this.codeMirror.on('drop', (cm, e) => {
        this._ngZone.run(() => this.dropFiles(cm, e));
      });
      this.codeMirror.setValue(this.value);
    });
  }
  ngDoCheck() {
    if (!this._differ) {
      return;
    }
    // check options have not changed
    const changes = this._differ.diff(this._options);
    if (changes) {
      changes.forEachChangedItem(option => this.setOptionIfChanged(option.key, option.currentValue));
      changes.forEachAddedItem(option => this.setOptionIfChanged(option.key, option.currentValue));
      changes.forEachRemovedItem(option => this.setOptionIfChanged(option.key, option.currentValue));
    }
  }
  ngOnDestroy() {
    // is there a lighter-weight way to remove the cm instance?
    if (this.codeMirror) {
      this.codeMirror.toTextArea();
    }
  }
  codemirrorValueChanged(cm, change) {
    const cmVal = cm.getValue();
    if (this.value !== cmVal) {
      this.value = cmVal;
      this.onChange(this.value);
    }
  }
  setOptionIfChanged(optionName, newValue) {
    if (!this.codeMirror) {
      return;
    }
    // cast to any to handle strictly typed option names
    // could possibly import settings strings available in the future
    this.codeMirror.setOption(optionName, newValue);
  }
  focusChanged(focused) {
    this.onTouched();
    this.isFocused = focused;
    this.focusChange.emit(focused);
  }
  scrollChanged(cm) {
    this.scroll.emit(cm.getScrollInfo());
  }
  cursorActive(cm) {
    this.cursorActivity.emit(cm);
  }
  dropFiles(cm, e) {
    this.drop.emit([cm, e]);
  }
  /** Implemented as part of ControlValueAccessor. */
  writeValue(value) {
    if (value === null || value === undefined) {
      return;
    }
    if (!this.codeMirror) {
      this.value = value;
      return;
    }
    const cur = this.codeMirror.getValue();
    if (value !== cur && normalizeLineEndings(cur) !== normalizeLineEndings(value)) {
      this.value = value;
      if (this.preserveScrollPosition) {
        const prevScrollPosition = this.codeMirror.getScrollInfo();
        this.codeMirror.setValue(this.value);
        this.codeMirror.scrollTo(prevScrollPosition.left, prevScrollPosition.top);
      } else {
        this.codeMirror.setValue(this.value);
      }
    }
  }
  /** Implemented as part of ControlValueAccessor. */
  registerOnChange(fn) {
    this.onChange = fn;
  }
  /** Implemented as part of ControlValueAccessor. */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /** Implemented as part of ControlValueAccessor. */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
    this.setOptionIfChanged('readOnly', this.disabled);
  }
}
CodemirrorComponent.ɵfac = function CodemirrorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CodemirrorComponent)(i0.ɵɵdirectiveInject(i0.KeyValueDiffers), i0.ɵɵdirectiveInject(i0.NgZone));
};
CodemirrorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CodemirrorComponent,
  selectors: [["ngx-codemirror"]],
  viewQuery: function CodemirrorComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.ref = _t.first);
    }
  },
  inputs: {
    className: "className",
    name: "name",
    autoFocus: "autoFocus",
    options: "options",
    preserveScrollPosition: "preserveScrollPosition"
  },
  outputs: {
    cursorActivity: "cursorActivity",
    focusChange: "focusChange",
    scroll: "scroll",
    drop: "drop"
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CodemirrorComponent),
    multi: true
  }])],
  decls: 3,
  vars: 7,
  consts: [["ref", ""], ["autocomplete", "off", 3, "name", "autofocus"]],
  template: function CodemirrorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "textarea", 1, 0);
      i0.ɵɵtext(2, "    ");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMapInterpolate1("ngx-codemirror ", ctx.className, "");
      i0.ɵɵclassProp("ngx-codemirror--focused", ctx.isFocused);
      i0.ɵɵproperty("name", ctx.name)("autofocus", ctx.autoFocus);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CodemirrorComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-codemirror',
      template: `
    <textarea
      [name]="name"
      class="ngx-codemirror {{ className }}"
      [class.ngx-codemirror--focused]="isFocused"
      autocomplete="off"
      [autofocus]="autoFocus"
      #ref
    >
    </textarea>
  `,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CodemirrorComponent),
        multi: true
      }],
      preserveWhitespaces: false,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i0.KeyValueDiffers
    }, {
      type: i0.NgZone
    }];
  }, {
    className: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    autoFocus: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    preserveScrollPosition: [{
      type: Input
    }],
    cursorActivity: [{
      type: Output
    }],
    focusChange: [{
      type: Output
    }],
    scroll: [{
      type: Output
    }],
    drop: [{
      type: Output
    }],
    ref: [{
      type: ViewChild,
      args: ['ref']
    }]
  });
})();
class CodemirrorModule {}
CodemirrorModule.ɵfac = function CodemirrorModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CodemirrorModule)();
};
CodemirrorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CodemirrorModule
});
CodemirrorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CodemirrorModule, [{
    type: NgModule,
    args: [{
      exports: [CodemirrorComponent],
      declarations: [CodemirrorComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CodemirrorComponent, CodemirrorModule };
