<app-page-header title="Utenti" [showBack]="false">
  <div class="d-flex align-items-center">
    <button
      class="mr-4"
      mat-flat-button
      color="primary"
      [routerLink]="['new']"
      *ngIf="authService.hasRole('USER_CAN_WRITE')"
    >
      Crea utente
    </button>

    <bitf-search
      (valueChanges)="searchText = $event"
      placeholder="Cerca utente"
      label="Cerca utente"
      [isInToolbar]="true"
      [hasSearchIcon]="false"
    >
    </bitf-search>
  </div>
</app-page-header>

<div class="container-fluid py-3">
  <app-dynamic-table
    [service]="userService"
    [columns]="dataColumns"
    [showDeleteBtn]="authService.hasRole('USER_CAN_DELETE')"
    [searchText]="searchText"
  >
    <ng-template #booleanTpl let-value="value">
      <label *ngIf="value"> Si </label>

      <label *ngIf="!value"> No </label>
    </ng-template>
  </app-dynamic-table>
</div>
