import { Component, OnInit } from '@angular/core';

import { InformationTypeService } from 'app/armp/services/information-type.service';
import { AuthService } from 'app/armp/core/auth.service';
import { IDynamicTableColumnData } from 'common/interfaces/dynamic-column.interface';

@Component({
    selector: 'app-information-type-list',
    templateUrl: './information-type-list.component.html',
    styleUrls: ['./information-type-list.component.scss'],
    standalone: false
})
export class InformationTypeListComponent implements OnInit {
  dataColumns: IDynamicTableColumnData[] = [];
  searchText: string;

  constructor(
    public informationTypeService: InformationTypeService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataColumns.push(
      {
        name: 'Codice',
        prop: 'code',
        sortable: true,
        styleHeader: { width: '300px' },
      },
      {
        name: 'Nome',
        prop: 'name',
        sortable: true,
      }
    );
  }
}
