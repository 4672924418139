<div class="d-flex justify-content-end p-1">
  <button
    mat-flat-button
    color="primary"
    type="button"
    (click)="addPolicy()"
    [disabled]="!canAddPolicy()"
  >
    Aggiungi Policy
  </button>
</div>

<table
  mat-table
  [dataSource]="policies"
  class="mat-elevation-z0 w-100"
  *ngIf="policies.length"
>
  <!-- Nome -->
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef>Nome</th>
    <td mat-cell *matCellDef="let policy; let i = index" ngForm #form="ngForm">
      <span *ngIf="!canEditPolicyName(policy)">{{ policy.name }}</span>
      <mat-form-field class="w-100" *ngIf="canEditPolicyName(policy)">
        <mat-label>Nome</mat-label>
        <input
          matInput
          name="name"
          type="text"
          maxlength="255"
          [(ngModel)]="policy.name"
          #nameModel="ngModel"
        />
        <mat-error
          appFormFieldError
          [formFieldModel]="nameModel"
          [form]="form"
        ></mat-error>
      </mat-form-field>

      <mat-chip-option
        class="ml-2 chip-small bg-grey text-white"
        [disableRipple]="true"
        [selectable]="false"
        *ngIf="policy.voucherId"
      >
        Voucher
      </mat-chip-option>

      <mat-chip-option
        class="ml-2 chip-small bg-warn text-white"
        [disableRipple]="true"
        [selectable]="false"
        *ngIf="policy.voucherId && policy.overwriteMode === 'OVERWRITE'"
      >
        Sovrascritto
      </mat-chip-option>

      <mat-chip-option
        class="ml-2 chip-small bg-grey text-white"
        [disableRipple]="true"
        [selectable]="false"
        *ngIf="policy.voucherId && policy.overwriteMode === 'ADD'"
      >
        Modificato
      </mat-chip-option>

      <mat-chip-option
        class="ml-2 chip-small bg-error text-white"
        [disableRipple]="true"
        [selectable]="false"
        *ngIf="policy.voucherId && policy.overwriteMode === 'REMOVE'"
      >
        Rimosso
      </mat-chip-option>

      <mat-chip-option
        class="ml-2 chip-small bg-error text-white"
        [disableRipple]="true"
        [selectable]="false"
        *ngIf="policy.licenseInstanceId && policy.overwriteMode === 'ADD'"
      >
        Aggiunto
      </mat-chip-option>
    </td>
  </ng-container>

  <!-- Tipo -->
  <ng-container matColumnDef="tipo">
    <th mat-header-cell *matHeaderCellDef>Tipo</th>
    <td mat-cell *matCellDef="let policy">{{ policy.implementation.name }}</td>
  </ng-container>

  <!-- Indicatori -->
  <ng-container matColumnDef="indicatori">
    <th mat-header-cell *matHeaderCellDef>Indicatori</th>
    <td
      mat-cell
      *matCellDef="let policy; let index = index"
      ngForm
      #form="ngForm"
    >
      <app-policy-cfg
        name="configuration"
        [(ngModel)]="policies[index]"
        [editMode]="isEditingPolicy(policy)"
        [viewMode]="'inline'"
        [showActual]="true"
        [topForm]="form"
        size="sm"
      ></app-policy-cfg>
    </td>
  </ng-container>

  <!-- Azioni -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let policy" ngForm #form="ngForm">
      <div class="d-flex align-items-center justify-content-end">
        <button
          mat-icon-button
          matTooltip="Modifica"
          (click)="startPolicyEditing(policy, 'ADD')"
          *ngIf="policy.editable && !isEditingPolicy(policy)"
        >
          <mat-icon>edit</mat-icon>
        </button>

        <button
          mat-icon-button
          matTooltip="Sovrascrivi"
          (click)="startPolicyEditing(policy, 'OVERWRITE')"
          *ngIf="
            policy.editable && policy.voucherId && !isEditingPolicy(policy)
          "
        >
          <mat-icon>shuffle</mat-icon>
        </button>

        <button
          mat-icon-button
          matTooltip="Salva"
          (click)="stopPolicyEditing(policy, form)"
          *ngIf="isEditingPolicy(policy)"
        >
          <mat-icon>check</mat-icon>
        </button>

        <button
          mat-icon-button
          matTooltip="Rimuovi"
          (click)="removePolicy(policy)"
          *ngIf="policy.deletable"
        >
          <mat-icon>remove</mat-icon>
        </button>

        <button
          mat-icon-button
          matTooltip="Ripristina"
          (click)="recoverPolicy(policy)"
          *ngIf="policy.recoverable"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<table class="table table-hover">
  <tbody>
    <tr
      *ngFor="let row of policies; let $$index = index"
      ngForm
      #form="ngForm"
      [ngClass]="getPolicyRowClass(row)"
    ></tr>
  </tbody>
</table>
