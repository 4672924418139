import {NgModule, Optional, SkipSelf,} from '@angular/core';
import {CommonModule, PlatformLocation, registerLocaleData,} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import localeIt from '@angular/common/locales/it';
import { HttpClient } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule} from '@ngx-translate/core';

// Components
import {FooterComponent} from 'app/armp/core/footer/footer.component';
import {SidebarComponent} from 'app/armp/core/sidebar/sidebar.component';
import {NavbarComponent} from 'app/armp/core/navbar/navbar.component';

// Services
import {ErrorService} from 'app/armp/core/error.service';

// Guards
import {CanActivateCaptcha} from 'app/armp/core/routing/can-activate-captcha';
import {CanActivateAdmin} from 'app/armp/core/routing/can-activate-admin';
import {CanActivateViaAuthGuard} from 'app/armp/core/routing/can-activate-via-auth';
import {
    CustomerResolver,
    LicenseInstanceResolver,
    PublicVoucherResolver,
    VoucherResolver,
} from 'app/armp/core/routing/resolvers';

// Modules
import {MATERIAL_MODULES} from 'app/material-modules';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {RouterModule} from '@angular/router';

registerLocaleData(localeIt);

export function translateLoader(
    http: HttpClient,
    platformLocation: PlatformLocation
) {
    return new TranslateHttpLoader(
        http,
        platformLocation.getBaseHrefFromDOM() + 'assets/i18n/'
    );
}

@NgModule({
    imports: [
        CommonModule,
        MATERIAL_MODULES,
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        CodemirrorModule,
        RouterModule,
    ],
    providers: [
        VoucherResolver,
        PublicVoucherResolver,
        CustomerResolver,
        LicenseInstanceResolver,
        CanActivateViaAuthGuard,
        CanActivateAdmin,
        CanActivateCaptcha,
        ErrorService,
    ],
    declarations: [FooterComponent, SidebarComponent, NavbarComponent],
    exports: [
        FooterComponent,
        SidebarComponent,
        NavbarComponent,
        CodemirrorModule,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only'
            );
        }
    }
}
