import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { CustomerService } from 'app/armp/services/customer.service';
import { AuthService } from 'app/armp/core/auth.service';
import { IDynamicTableColumnData } from 'common/interfaces/dynamic-column.interface';

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.scss'],
    standalone: false
})
export class CustomerListComponent implements OnInit {
  @ViewChild('createdOnCellTemplate', { static: true })
  createdOnCellTemplate: TemplateRef<any>;
  @ViewChild('vatNumberCellTemplate', { static: true })
  vatNumberCellTemplate: TemplateRef<any>;

  dataColumns: IDynamicTableColumnData[] = [];
  searchText = '';

  constructor(
    public customerService: CustomerService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataColumns.push(
      {
        name: 'Ragione Sociale',
        prop: 'name',
        sortable: true,
        styleHeader: { width: '215px' },
      },
      {
        name: 'Partita Iva / C.F.',
        prop: 'vatNumber',
        sortable: true,
        cellTemplate: this.vatNumberCellTemplate,
        styleHeader: { width: '160px' },
      },
      {
        name: 'Cliente Dal',
        prop: 'createdOn',
        sortable: true,
        searchable: false,
        cellTemplate: this.createdOnCellTemplate,
      },
      {
        name: '# Voucher',
        prop: 'totalVouchers',
        sortable: false,
        searchable: false,
      },
      {
        name: '# Prodotti',
        prop: 'totalProducts',
        sortable: false,
        searchable: false,
      },
      {
        name: '# Istanze',
        prop: 'totalLicenseInstances',
        sortable: false,
        searchable: false,
      },
      {
        name: 'Referente',
        prop: 'fullName',
        sortable: false,
        searchable: false,
      }
    );
  }
}
