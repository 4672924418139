// Ng2 Modules
import { Inject, LOCALE_ID, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { TranslateModule, TranslateService } from "@ngx-translate/core";

// Services
import { LicenseTemplateService } from "./services/license-template.service";
import { CustomerService } from "./services/customer.service";
import { VoucherService } from "./services/voucher.service";
import { ProductService } from "./services/product.service";
import { PolicyService } from "./services/policy.service";
import { SystemService } from "app/armp/services/system.service";
import { LicenseInstanceService } from "app/armp/services/license-instance-service";
import { InformationService } from "app/armp/services/information.service";
import { InternalResourceService } from "app/armp/services/internal-resource.service";
import { CountryService } from "app/armp/services/country.service";
import { PolicyInstanceService } from "app/armp/services/policy-instance.service";
import { PolicyVoucherService } from "app/armp/services/policy-voucher.service";
import { ProfileService } from "app/armp/services/profile.service";
import { RoleService } from "app/armp/services/role.service";
import { UserService } from "app/armp/services/user.service";
import { InformationTypeService } from "app/armp/services/information-type.service";
import { AdminService } from "app/armp/services/admin.service";
import { CertificateService } from "app/armp/services/certificate.service";
import { CertificateBindingService } from "app/armp/services/certificate-binding.service";
import { VoucherPublicService } from "app/armp/services/voucher-public.service";
import { CustomerPublicService } from "app/armp/services/customer-public.service";
import { LicenseInstancePublicService } from "app/armp/services/license-instance-public.service";
import { CertificateBindingPublicService } from "app/armp/services/certificate-binding-public.service";

// Components
import { HomeComponent } from "./home/home.component";
import { ArmpComponent } from "./armp/armp.component";
import { ErrorComponent } from "./error/error.component";

import { LicenseTemplateCreateComponent } from "./license-template/license-template-create/license-template-create.component";
import { LicenseTemplateListComponent } from "./license-template/license-template-list/license-template-list.component";
import { ProductListComponent } from "./product/product-list/product-list.component";
import { ProductEditComponent } from "./product/product-edit/product-edit.component";
import { CustomerListComponent } from "./customer/customer-list/customer-list.component";
import { CustomerCreateComponent } from "./customer/customer-create/customer-create.component";
import { SystemListComponent } from "./system/system-list/system-list.component";
import { SystemEditComponent } from "./system/system-edit/system-edit.component";
import { LicenseInstanceEditComponent } from "./license-instance/license-instance-edit/license-instance-edit.component";
import { LicenseInstancePolicyComponent } from "./license-instance/license-instance-policy/license-instance-policy.component";
import { LicenseInstanceInfoComponent } from "./license-instance/license-instance-info/license-instance-info.component";
import { ProfileListComponent } from "./profile/profile-list/profile-list.component";
import { ProfileEditComponent } from "./profile/profile-edit/profile-edit.component";
import { RoleListComponent } from "./role/role-list/role-list.component";
import { RoleEditComponent } from "./role/role-edit/role-edit.component";
import { UserListComponent } from "./user/user-list/user-list.component";
import { UserEditComponent } from "./user/user-edit/user-edit.component";
import { InformationTypeEditComponent } from "./information-type/information-type-edit/information-type-edit.component";
import { InformationTypeListComponent } from "./information-type/information-type-list/information-type-list.component";
import { CustomerEditComponent } from "./customer/customer-edit/customer-edit.component";

import { AdminComponent } from "./admin/admin.component";

// Modules
import { CoreModule } from "app/armp/core/core.module";
import { SharedModule } from "app/armp/shared/shared.module";
import { AutomaticUserBindingService } from "./services/automaticuser-binding.service";
import { VoucherModule } from "./voucher/voucher.module";
import { PolicyModule } from "./policy/policy.module";
import { DynamicTableModule } from "./crud/dynamic-table/dynamic-table.module";
import { CertificateModule } from "./certificate/certificate.module";
import { FormValidatorModule } from "./shared/form-validator/form-validator.module";
import { ConfigService } from "./services/config.service";

@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    RouterModule,
    VoucherModule,
    PolicyModule,
    DynamicTableModule,
    CertificateModule,
    TranslateModule.forChild(),
    FormValidatorModule,
  ],
  providers: [
    LicenseTemplateService,
    CustomerService,
    VoucherService,
    ProductService,
    PolicyService,
    SystemService,
    InformationService,
    InternalResourceService,
    LicenseInstanceService,
    PolicyInstanceService,
    PolicyVoucherService,
    CountryService,
    ProfileService,
    RoleService,
    UserService,
    InformationTypeService,
    AdminService,
    CertificateService,
    CertificateBindingService,
    VoucherPublicService,
    CustomerPublicService,
    LicenseInstancePublicService,
    CertificateBindingPublicService,
    AutomaticUserBindingService,
  ],
  declarations: [
    HomeComponent,
    ArmpComponent,
    LicenseTemplateCreateComponent,
    LicenseTemplateListComponent,
    ProductListComponent,
    ProductEditComponent,
    CustomerListComponent,
    CustomerCreateComponent,
    SystemListComponent,
    SystemEditComponent,
    LicenseInstanceEditComponent,
    LicenseInstancePolicyComponent,
    LicenseInstancePolicyComponent,
    ProfileListComponent,
    ProfileEditComponent,
    RoleListComponent,
    RoleEditComponent,
    UserListComponent,
    UserEditComponent,
    LicenseInstanceInfoComponent,
    InformationTypeEditComponent,
    InformationTypeListComponent,
    AdminComponent,
    ErrorComponent,
    CustomerEditComponent,
  ],
  exports: [ArmpComponent],
})
export class ArmpModule {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    translate: TranslateService,
    configService: ConfigService
  ) {
    configService.loadConfig().then((r) => {
      this.initializeTranslatorService(locale, translate);
    });
  }

  private initializeTranslatorService(
    locale: string,
    translate: TranslateService
  ) {
    const lang = locale.split("-")[0];
    // defineLocale('it', itLocale);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(lang);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(lang);
  }
}
