<app-page-header
  [title]="
    activatedRoute.snapshot.paramMap.get('id')
      ? 'Modifica ruolo'
      : 'Crea nuovo ruolo'
  "
  [breadcrumb]="['Ruoli']"
>
  <div class="d-flex">
    <button mat-stroked-button color="primary" class="mr-3" (click)="onBack()">
      Annulla
    </button>
    <button
      mat-flat-button
      [disabled]="!form.valid"
      color="primary"
      (click)="save()"
      *ngIf="authService.hasRole('ROLE_CAN_WRITE')"
    >
      Salva
    </button>
  </div>
</app-page-header>

<div class="container-fluid py-3">
  <form #form="ngForm" novalidate>
    <div class="w-75 m-auto">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex align-items-center text-primary">
          <mat-icon class="mr-2">assignment_ind</mat-icon>
          Definizione Ruolo
        </mat-card-title>

        <mat-card-content>
          <mat-form-field class="w-100">
            <mat-label>Nome</mat-label>
            <input
              matInput
              type="text"
              maxlength="255"
              name="name"
              placeholder="Inserisci nome"
              [(ngModel)]="entity.name"
              required
              disabled
              #nameModel="ngModel"
            />
            <mat-error
              appFormFieldError
              [formFieldModel]="nameModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label>Descrizione</mat-label>
            <textarea
              matInput
              type="text"
              maxlength="255"
              name="description"
              placeholder="Inserisci descrizione"
              [(ngModel)]="entity.description"
              required
              #descriptionModel="ngModel"
            ></textarea>
            <mat-error
              appFormFieldError
              [formFieldModel]="descriptionModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>
