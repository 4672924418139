import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { ProfileService } from 'app/armp/services/profile.service';
import { Profile } from 'app/armp/models/profile';
import { RoleService } from 'app/armp/services/role.service';
import { AuthService } from 'app/armp/core/auth.service';
import { ToastService } from 'app/armp/services/toast.service';
import { LoaderService } from 'app/armp/core/loader.service';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.scss'],
    standalone: false
})
export class ProfileEditComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: false }) private form!: NgForm;

  entity!: Profile;
  roles = [];
  rolesSelected = {};

  private subscription: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private profileService: ProfileService,
    private roleService: RoleService,
    private toastService: ToastService,
    private location: Location,
    private loaderService: LoaderService,
    private translateService: TranslateService
  ) {}

  get selectedOptions() {
    return this.roles.filter((r) => this.rolesSelected[r.id]);
  }

  ngOnInit() {
    this.entity = new Profile();

    this.roleService.getAll().then((response) => {
      this.roles = response;
    });

    this.subscription = this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.profileService.getById(+params.id).then((p) => {
          this.entity = p;
          this.entity.roles = [];
        });

        this.roleService.getByProfileId(+params.id).then((response) => {
          response.forEach((r) => {
            this.rolesSelected[r] = true;
          });
        });
      }
    });
  }

  onBack() {
    this.location.back();
  }

  save() {
    this.form.onSubmit(null);
    if (this.form.valid) {
      if (this.needRoles()) {
        this.toastService.error({
          message: 'Errore - Selezionare almeno un Ruolo',
        });
      } else {
        this.loaderService.show();
        this.entity.roles = this.selectedOptions;
        this.profileService
          .save(this.entity)
          .then(() => {
            this.loaderService.hide();
            this.toastService.success({
              message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
            });
            this.onBack();
          })
          .catch(() => {
            this.loaderService.hide();
          });
      }
    }
  }

  needRoles() {
    return this.entity.name !== 'ADMIN' && this.selectedOptions.length === 0;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
