import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';
import {AuthService} from 'app/armp/core/auth.service';

import {ToastService} from 'app/armp/services/toast.service';
import {BaseResponse} from '../../models/response/baseResponse';

export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,
                private toastService: ToastService,
                private translator: TranslateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            map(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        return this.manipulateResponse(event);
                    }
                    return event;
                }
            ),
            catchError((error: HttpErrorResponse) => {
                this.manipulateResponse(error);
                return throwError(() => error);
            })
        );
    }

    /**
     * check for errors (api or http)
     */
    manipulateResponse<T>(event: T): T {
        /** api error */
        if (event instanceof HttpResponse && this.checkHaveApiErrors(event.body)) {
            this.handleApiError(event.body);
        }

        /** Others responses */
        if (!(event instanceof HttpResponseBase)) {
            return event;
        }

        /** Http error */
        this.checkHaveHttpError(event);

        return event;
    }

    private checkHaveApiErrors(responseBody: any): boolean {
        return responseBody?.metadata?.errors !== null && responseBody?.metadata?.errors?.length > 0;
    }

    /**
     * Check http error type and open an alert with a message
     */
    private checkHaveHttpError(event: any): void {
        /** 500 */
        if (event.status >= 500 || event.status <= 0) {
            this.handleServerErrorError();
        }
        /** 400 */
        if (event.status === 400) {
            this.handleBadRequestError();
            /** 401 */
        } else if (event.status === 401) {
            this.handleUnauthorizedError();
        }
    }

    /** try to cast response as BaseResponseError and throw error */
    private handleApiError(responseBody: BaseResponse | any) {
        let errorMessage: string;
        if (responseBody?.metadata?.errors[0]?.message) {
            errorMessage = responseBody?.metadata?.errors[0].message;
        } else {
            errorMessage = this.translator.instant('ERROR.GENERIC');
        }


        this.toastService.error({
            message: this.translator.instant('TOASTR.ERROR') + ': ' + errorMessage,
        });


        // BaseResponseError
        throw responseBody ? (responseBody as BaseResponse) : errorMessage;
    }


    // handle 400 errors
    private handleBadRequestError(): void {
        this.toastService.error({
            message:
                this.translator.instant('TOASTR.ERROR') +
                ' ' +
                this.translator.instant('ERROR.BAD_REQUEST'),
        });
    }

    // handle 401 errors
    private handleUnauthorizedError(): void {
        this.toastService.error({
            message:
                this.translator.instant('TOASTR.ERROR') +
                ' ' +
                this.translator.instant('ERROR.NOT_AUTHORIZED'),
        });
        this.authService.logout();
    }

    // handle 500 errors
    private handleServerErrorError(): void {
        this.toastService.error({
            message:
                this.translator.instant('TOASTR.ERROR') +
                ' ' +
                this.translator.instant('ERROR.GENERIC'),
        });
    }

    convertDateToString(input) {
        // Ignore things that aren't objects.
        if (typeof input !== 'object') {
            return input;
        }

        for (const key in input) {
            if (!input.hasOwnProperty(key)) {
                continue;
            }

            const value = input[key];

            if (!value) {
                continue;
            }

            if (typeof value === 'object' && value.hasOwnProperty('momentObj')) {
                input[key] = value.momentObj.toISOString();
            } else if (typeof value === 'object') {
                this.convertDateToString(value);
            }
        }
    }
}
