import {
  APP_INITIALIZER,
  EnvironmentInjector,
  EnvironmentProviders,
  LOCALE_ID,
  NgModule,
  inject,
  provideAppInitializer,
  runInInjectionContext,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import localeIt from "@angular/common/locales/it";
import { PlatformLocation, registerLocaleData } from "@angular/common";

import {
  AutoRefreshTokenService,
  KeycloakAngularModule,
  KeycloakService,
  provideKeycloak,
  ProvideKeycloakOptions,
  UserActivityService,
  withAutoRefreshToken,
} from "keycloak-angular";

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { ArmpModule } from "./armp/armp.module";
import { ArmpComponent } from "./armp/armp/armp.component";
import { RoutingModule } from "./armp/routing/routing.module";
import { ConfigService } from "./armp/services/config.service";

import { CustomHttpInterceptor } from "./armp/core/http/custom-http-interceptor";
import { AuthService } from "./armp/core/auth.service";
import { ToastService } from "./armp/services/toast.service";

function appInitializer(
  configService: ConfigService,
  keycloakService: KeycloakService
) {
  return (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        await configService.loadConfig();
        await keycloakService.init(configService.keycloackOptions);
        resolve();
      } catch (error) {
        console.error(error);
        reject();
        return;
      }
    });
  };
}

export function translateLoader(
  http: HttpClient,
  platformLocation: PlatformLocation
) {
  return new TranslateHttpLoader(
    http,
    platformLocation.getBaseHrefFromDOM() + "assets/i18n/"
  );
}

registerLocaleData(localeIt);

@NgModule({
  declarations: [],
  bootstrap: [ArmpComponent],
  imports: [
    RoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ArmpModule,
    KeycloakAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [HttpClient, PlatformLocation],
      },
    }),
  ],
  providers: [
    provideAppInitializer(() => {
      const initializerFn = appInitializer(
        inject(ConfigService),
        inject(KeycloakService)
      );
      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
      deps: [AuthService, ToastService, TranslateService],
    },
    { provide: LOCALE_ID, useValue: "it-IT" },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
