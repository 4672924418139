import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

import { VoucherService } from 'app/armp/services/voucher.service';
import { InformationService } from 'app/armp/services/information.service';
import { PolicyService } from 'app/armp/services/policy.service';
import { LicenseInstanceService } from 'app/armp/services/license-instance-service';
import { AuthService } from 'app/armp/core/auth.service';
import { HtmlDialogComponent } from 'app/armp/shared/html-dialog/html-dialog.component';
import { ToastService } from 'app/armp/services/toast.service';
import { IDynamicTableColumnData } from 'common/interfaces/dynamic-column.interface';

@Component({
    selector: 'app-license-instance-edit',
    templateUrl: './license-instance-edit.component.html',
    styleUrls: ['./license-instance-edit.component.scss'],
    standalone: false
})
export class LicenseInstanceEditComponent implements OnInit {
  @ViewChild('informationGridDetailTpl', { static: true })
  private informationGridDetailTpl: TemplateRef<any>;

  @ViewChild('dateTimeTpl', { static: true })
  private dateTimeTpl: TemplateRef<any>;

  informationTableCols: IDynamicTableColumnData[] = [];
  voucher: any;
  entity: any;
  activeTab = 0;
  tabs = ['Policy Associate', 'Informazioni'];

  private policyClassnames = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private voucherService: VoucherService,
    private informationService: InformationService,
    private policyService: PolicyService,
    private licenseInstanceService: LicenseInstanceService,
    private translateService: TranslateService,
    public authService: AuthService,
    private dialog: MatDialog,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.entity = this.activatedRoute.snapshot.data['licenseInstance'];

    this.voucherService.getById(this.entity.voucherId).then((v) => {
      this.voucher = v;
    });

    this.policyService.getImplementations().then((classnames) => {
      this.policyClassnames = classnames;
    });

    this.informationTableCols.push(
      {
        name: 'Data Ricezione',
        prop: 'sentOn',
        cellTemplate: this.dateTimeTpl,
        styleHeader: { width: '200px' },
      },
      {
        name: 'Tipo',
        prop: 'informationType',
        sortable: false,
      },
      {
        name: '',
        prop: 'links',
        cellTemplate: this.informationGridDetailTpl,
      }
    );
  }

  fetchInformations(event) {
    event.promise = this.informationService.getGridByLicenseInstance(
      event.dataTableRequest,
      this.entity.id
    );
  }

  openInformationModal(information) {
    if (information.html) {
      this.openHtmlDialog(information.html);
    } else {
      this.informationService.getHtml(information.id).then((html) => {
        this.openHtmlDialog(html);
      });
    }
  }

  private openHtmlDialog(html: any) {
    if (!html || html === '') {
      this.toastService.open({ message: 'Nessun dettaglio' });
      return;
    }
    this.dialog.open(HtmlDialogComponent, {
      data: {
        html,
      },
    });
  }

  get breadcrumb() {
    if (this.voucher) {
      return [
        this.voucher.customer.displayName,
        'Voucher ' + this.voucher.code,
        'Istanza ' + this.entity.machineUid,
      ];
    } else {
      return [];
    }
  }

  reassign() {
    if (
      confirm(
        this.translateService.instant(
          'VOUCHER.REASSIGN_LICENSE_INSTANCE_CONFIRM'
        )
      )
    ) {
      this.licenseInstanceService.reassign(this.entity.id).then((e) => {
        this.entity = e;
        this.toastService.success({
          message: this.translateService.instant(
            'LICENSE_INSTANCE.REASSIGN_SUCCESS'
          ),
        });
      });
    }
  }

  get canEdit() {
    return this.entity.active;
  }
}
