import { Component, OnInit } from '@angular/core';

import { AuthService } from 'app/armp/core/auth.service';
import { ProductService } from 'app/armp/services/product.service';
import { IDynamicTableColumnData } from 'common/interfaces/dynamic-column.interface';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    standalone: false
})
export class ProductListComponent implements OnInit {
  dataColumns: IDynamicTableColumnData[] = [];
  searchText: string;

  constructor(
    public productService: ProductService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataColumns.push(
      {
        name: 'Codice',
        prop: 'slug',
        sortable: true,
        styleHeader: { width: '200px' },
      },
      {
        name: 'Nome',
        prop: 'name',
        sortable: true,
        styleHeader: { width: '200px' },
      },
      {
        name: 'Descrizione',
        prop: 'description',
        sortable: true,
      }
    );
  }
}
