import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';

import { AdminService } from 'app/armp/services/admin.service';
import { ToastService } from '../services/toast.service';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: false
})
export class AdminComponent implements OnInit, OnDestroy {
  public status: string;
  public breadcrumb = [];

  private migrationInterval;

  constructor(
    private adminService: AdminService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.getMigrationStatus();

    this.migrationInterval = setInterval(() => {
      this.getMigrationStatus();
    }, 5000);
  }

  getMigrationStatus() {
    this.adminService.getMigrationStatus().then((status) => {
      this.status = status;
    });
  }

  canMigrate() {
    return this.status === 'NOT_STARTED' || this.status === 'ERROR';
  }

  startMigration() {
    if (
      confirm(
        `Eseguire la migrazione dal vecchio db? L'operazione potrebbe richiedere qualche minuto`
      )
    ) {
      this.adminService
        .migrate()
        .then(() => {
          this.toastService.success({
            message: 'Migrazione effettuata con successo',
          });

          this.getMigrationStatus();
        })
        .catch(() => {
          this.getMigrationStatus();
        });

      this.getMigrationStatus();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.migrationInterval);
  }
}
