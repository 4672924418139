<div class="container d-flex justify-content-center mt-5">
  <div class="d-flex flex-column align-items-center">
    <h1 class="d-flex align-items-center">
      <mat-icon color="warn" class="mr-3">error</mat-icon> Errore richiesta
    </h1>

    <div *ngIf="errors.length">
      <div *ngFor="let error of errors" class="mt-2">
        <app-alert [text]="error.message" type="error"></app-alert>
      </div>
    </div>

    <div class="mt-2" *ngIf="errors.length === 0">
      <app-alert [text]="'Qualcosa è andato storto'" type="warn"></app-alert>
    </div>

    <div class="d-flex mt-5">
      <button mat-stroked-button color="primary" (click)="onBack()">
        Riprova
      </button>

      <button mat-flat-button color="primary" class="ml-3" [routerLink]="['/']">
        Torna alla home page
      </button>
    </div>
  </div>
</div>
