import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

import {AuthService} from 'app/armp/core/auth.service';
import {MainMenu} from '../../../../common/interfaces/MainMenu';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: false
})
export class SidebarComponent implements OnInit {

    private datiComuniCollapsed;
    menuList: MainMenu[] = [];


    constructor(
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        public authService: AuthService
    ) {
        this.menuList = this.getMenu();
    }

    ngOnInit() {
        this.datiComuniCollapsed = this.location.path().startsWith('/customer') || this.location.path() === '';
    }

    private getMenu(): MainMenu[] {
        return [
            {
                routerLink: 'voucher',
                routerLinkActive: 'active',
                hasRole: 'VOUCHER_CAN_READ',
                label: 'MENU.VOUCHERS',
            },
            {
                routerLink: 'license-template',
                routerLinkActive: 'active',
                hasRole: 'LICENSE_TEMPLATE_CAN_READ',
                label: 'MENU.LICENSE_TEMPLATES',
            },
            {
                routerLink: 'product',
                routerLinkActive: 'active',
                hasRole: 'PRODUCT_CAN_READ',
                label: 'MENU.PRODUCTS',
            },
            {
                routerLink: 'system',
                routerLinkActive: 'active',
                hasRole: 'SYSTEM_CAN_READ',
                label: 'MENU.SYSTEMS',
            },
            {
                routerLink: 'profile',
                routerLinkActive: 'active',
                hasRole: 'PROFILE_CAN_READ',
                label: 'MENU.PROFILE',
            },
            {
                routerLink: 'user',
                routerLinkActive: 'active',
                hasRole: 'USER_CAN_READ',
                label: 'MENU.USER',
            },
            {
                routerLink: 'information-type',
                routerLinkActive: 'active',
                hasRole: 'INFORMATION_TYPE_CAN_READ',
                label: 'MENU.INFORMATION_TYPE',
            },
            {
                routerLink: 'certificate',
                routerLinkActive: 'active',
                hasRole: 'CERTIFICATE_CAN_READ',
                label: 'MENU.CERTIFICATE',
            },
            {
                routerLink: 'statistiche',
                routerLinkActive: 'active',
                hasRole: 'GENERIC_DATA_STAT_CAN_READ',
                label: 'MENU.STATISTICHE',
            },
        ];
    }
}
