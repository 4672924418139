<app-page-header
  [title]="'ID ' + customer.id + ' | ' + customer.name"
  [breadcrumb]="['Customer']"
>
</app-page-header>

<div class="container-fluid py-3">
  <div class="row">
    <div class="col-24 col-sm-8" ngForm #customerForm="ngForm">
      <mat-card appearance="outlined">
        <mat-card-title class="text-primary d-flex align-items-center">
          <mat-icon class="mr-2">contact_page</mat-icon>
          Dati cliente
        </mat-card-title>

        <mat-card-content>
          <mat-list *ngIf="!editMode">
            <mat-list-item>
              <h4 class="title" *ngIf="customer.organization" mat-line>
                Ragione Sociale
              </h4>
              <h4 class="title" *ngIf="!customer.organization" mat-line>
                Nominativo
              </h4>
              <div mat-line>{{ customer.name }}</div>
            </mat-list-item>

            <mat-list-item class="border-top" *ngIf="customer.organization">
              <h4 class="title" mat-line>Partita IVA</h4>
              <div mat-line>{{ customer.vatNumber }}</div>
            </mat-list-item>

            <mat-list-item class="border-top" *ngIf="!customer.organization">
              <h4 class="title" mat-line>Codice Fiscale</h4>
              <div mat-line>{{ customer.fiscalCode }}</div>
            </mat-list-item>

            <mat-list-item class="border-top">
              <h4 class="title" mat-line>Indirizzo</h4>
              <div mat-line>
                <span [hidden]="!customer.address"
                  >{{ customer.address }} - </span
                >{{ customer.city }}
                <span [hidden]="!customer.state">({{ customer.state }})</span>
              </div>
            </mat-list-item>

            <mat-list-item class="border-top">
              <h4 class="title" mat-line>Nome Contatto</h4>
              <div mat-line>
                <span *ngIf="customer.organization"
                  >{{ customer.firstName }} {{ customer.lastName }}</span
                >
                <span *ngIf="!customer.organization">-</span>
              </div>
            </mat-list-item>

            <mat-list-item class="border-top">
              <h4 class="title" mat-line>Contatto</h4>
              <div mat-line>
                {{ customer.email }}
              </div>
            </mat-list-item>
          </mat-list>

          <ng-container *ngIf="editMode">
            <mat-form-field class="w-100">
              <mat-label *ngIf="customer.organization"
                >Ragione Sociale</mat-label
              >
              <mat-label *ngIf="!customer.organization">Nominativo</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="name"
                placeholder="Inserisci nome"
                [(ngModel)]="customer.name"
                #customerNameModel="ngModel"
                required
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="customerNameModel"
                [form]="customerForm"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100" *ngIf="customer.organization">
              <mat-label>Partita IVA</mat-label>
              <input
                matInput
                type="text"
                name="vatNumber"
                placeholder="Inserisci Partita Iva"
                [(ngModel)]="customer.vatNumber"
                #vatNumberModel="ngModel"
                required
                validateVatCode
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="vatNumberModel"
                [form]="customerForm"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100" *ngIf="!customer.organization">
              <mat-label>Codice Fiscale</mat-label>
              <input
                matInput
                type="text"
                name="fiscalCode"
                placeholder="Inserisci Codice Fiscale"
                [(ngModel)]="customer.fiscalCode"
                #fiscalCodeModel="ngModel"
                required
                validateFiscalCode
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="fiscalCodeModel"
                [form]="customerForm"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Via</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="address"
                placeholder="Inserisci Via"
                [(ngModel)]="customer.address"
                #addressModel="ngModel"
                required
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="addressModel"
                [form]="customerForm"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Città</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="city"
                placeholder="Inserisci Città"
                [(ngModel)]="customer.city"
                #cityModel="ngModel"
                required
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="cityModel"
                [form]="customerForm"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Provincia</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="state"
                placeholder="Inserisci Provincia"
                [(ngModel)]="customer.state"
                #stateModel="ngModel"
                required
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="stateModel"
                [form]="customerForm"
              ></mat-error>
            </mat-form-field>

            <mat-divider></mat-divider>

            <ng-container *ngIf="customer.organization">
              <mat-form-field class="w-100">
                <mat-label>Nome</mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="255"
                  name="firstName"
                  placeholder="Inserisci Nome Contatto"
                  [(ngModel)]="customer.firstName"
                  #firstNameModel="ngModel"
                  required
                />
                <mat-error
                  appFormFieldError
                  [formFieldModel]="firstNameModel"
                  [form]="customerForm"
                ></mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Cognome</mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="255"
                  name="lastName"
                  placeholder="Inserisci Cognome Contatto"
                  [(ngModel)]="customer.lastName"
                  #lastNameModel="ngModel"
                  required
                />
                <mat-error
                  appFormFieldError
                  [formFieldModel]="lastNameModel"
                  [form]="customerForm"
                ></mat-error>
              </mat-form-field>
            </ng-container>

            <mat-form-field class="w-100">
              <mat-label>Contatto</mat-label>
              <input
                matInput
                type="text"
                maxlength="255"
                name="email"
                placeholder="Inserisci Email"
                [(ngModel)]="customer.email"
                #emailModel="ngModel"
                required
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="emailModel"
                [form]="customerForm"
              ></mat-error>
            </mat-form-field>
          </ng-container>
        </mat-card-content>

        <mat-card-actions align="end">
          <button
            mat-stroked-button
            color="primary"
            type="button"
            (click)="editCustomer()"
            *ngIf="!editMode && authService.hasRole('CUSTOMER_CAN_WRITE')"
          >
            <mat-icon class="mr-2">edit</mat-icon>
            Modifica
          </button>

          <button
            mat-stroked-button
            color="primary"
            type="button"
            class="mr-2"
            *ngIf="editMode"
            (click)="
              customerForm.reset(customerInitialValue); editMode = !editMode
            "
          >
            <mat-icon class="mr-2">close</mat-icon>
            Annulla
          </button>

          <button
            mat-flat-button
            color="primary"
            type="button"
            (click)="updateCustomer()"
            *ngIf="editMode && authService.hasRole('CUSTOMER_CAN_WRITE')"
            [disabled]="customerForm.invalid"
          >
            <mat-icon class="mr-2">check</mat-icon>
            Salva
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-24 col-sm-8">
      <mat-card appearance="outlined">
        <mat-card-title class="text-primary d-flex align-items-center">
          <mat-icon class="mr-2">monetization_on</mat-icon>
          Situazione Finanziaria
        </mat-card-title>

        <mat-card-content>
          <mat-list>
            <mat-list-item>
              <h4 class="title" mat-line>Voucher Totali</h4>
              <div mat-line>{{ vouchersCount.total }}</div>
            </mat-list-item>
            <mat-list-item class="border-top">
              <h4 class="title" mat-line>Voucher Attivi</h4>
              <div mat-line>{{ vouchersCount.active }}</div>
            </mat-list-item>
            <mat-list-item class="border-top">
              <h4 class="title" mat-line>Voucher Inattivi</h4>
              <div mat-line>
                {{ vouchersCount.total - vouchersCount.active }}
              </div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-24 col-sm-8">
      <mat-card appearance="outlined">
        <mat-card-title class="text-primary d-flex align-items-center">
          <mat-icon class="mr-2">vpn_key</mat-icon>
          Certificati Associati
        </mat-card-title>

        <mat-card-content ngForm #certificatesForm="ngForm">
          <app-alert
            text="Nessun certificato specifico presente"
            type="info"
            *ngIf="!certificates.length"
          ></app-alert>

          <app-certificate-management
            [(ngModel)]="certificates"
            name="certificates"
            [canManageCertificates]="authService.hasRole('CUSTOMER_CAN_WRITE')"
          >
            <ng-container certificateManagementActions>
              <button
                mat-flat-button
                color="primary"
                type="button"
                class="ml-2"
                (click)="saveCertificates()"
                *ngIf="authService.hasRole('CERTIFICATE_CAN_WRITE')"
                [disabled]="
                  certificatesForm.controls.certificates?.pristine ||
                  certificatesForm.invalid
                "
              >
                Salva
              </button>
            </ng-container>
          </app-certificate-management>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-24 mt-3">
      <mat-card appearance="outlined" class="mat-sub-card p-0">
        <mat-tab-group>
          <mat-tab
            label="{{ tab }}"
            *ngFor="let tab of tabs; let tabIndex = index"
          >
            <ng-template matTabContent>
              <div
                class="p-2"
                *ngIf="
                  tabIndex === 0 && authService.hasRole('VOUCHER_CAN_READ')
                "
              >
                <app-dynamic-table
                  #voucherGrid
                  [columns]="voucherCols"
                  (fetchData)="fetchVouchers($event)"
                  [sorts]="[{ prop: voucherCols[5].prop, dir: 'desc' }]"
                  [gridService]="voucherService"
                  [showDeleteBtn]="authService.hasRole('VOUCHER_CAN_DELETE')"
                  [detailRoute]="'/voucher'"
                  [trackBy]="'code'"
                  tableClass="shadow-none"
                  paginatorClass="shadow-none"
                >
                </app-dynamic-table>
              </div>

              <div
                class="p-2"
                *ngIf="
                  tabIndex === 1 && authService.hasRole('PRODUCT_CAN_READ')
                "
              >
                <app-dynamic-table
                  #productGrid
                  [columns]="productCols"
                  (fetchData)="fetchProducts($event)"
                  (dataFetched)="productsFetched($event)"
                  [detailRoute]="'/product'"
                  [gridService]="productService"
                  [sorts]="[{ prop: productCols[0].prop, dir: 'desc' }]"
                  [showDeleteBtn]="authService.hasRole('PRODUCT_CAN_DELETE')"
                  tableClass="shadow-none"
                  paginatorClass="shadow-none"
                >
                </app-dynamic-table>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #dateTpl let-value="value">
  {{ value | date : "shortDate" }}
</ng-template>

<ng-template #dateTimeTpl let-value="value">
  {{ value | date : "short" }}
</ng-template>

<ng-template #statusColTpl let-row="row" let-value="value">
  <div [ngSwitch]="value">
    <ng-container *ngSwitchCase="'VALID'">
      <mat-chip-option
        class="chip-small bg-success text-white"
        [disableRipple]="true"
        [selectable]="false"
      >
        VALIDO
      </mat-chip-option>
    </ng-container>

    <ng-container *ngSwitchCase="'WARNING'">
      <mat-chip-option
        class="chip-small bg-warning text-white"
        [disableRipple]="true"
        [selectable]="false"
      >
        ATTENZIONE
      </mat-chip-option>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <mat-chip-option
        class="chip-small bg-error-dark text-white"
        [disableRipple]="true"
        [selectable]="false"
      >
        NON VALIDO
      </mat-chip-option>
    </ng-container>
  </div>
</ng-template>

<ng-template #voucherActiveTmpl let-value="value" let-row="row">
  <div [ngSwitch]="value">
    <ng-container *ngSwitchCase="true">
      <mat-chip-option
        class="chip-small bg-success text-white"
        [disableRipple]="true"
        [selectable]="false"
      >
        Attivo
      </mat-chip-option>
    </ng-container>

    <ng-container *ngSwitchCase="false">
      <mat-chip-option
        class="chip-small bg-error text-white"
        [disableRipple]="true"
        [selectable]="false"
      >
        Non Attivo
      </mat-chip-option>
    </ng-container>
  </div>
</ng-template>
