import {VoucherService} from 'app/armp/services/voucher.service';

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {LicenseInstanceService} from 'app/armp/services/license-instance-service';
import {CustomerService} from 'app/armp/services/customer.service';

import {TranslateService} from '@ngx-translate/core';

import {CruService} from 'app/armp/crud/services/cru-service';
import {VoucherPublicService} from 'app/armp/services/voucher-public.service';
import {ErrorService} from 'app/armp/core/error.service';
import {AuthService} from 'app/armp/core/auth.service';
import {ToastService} from 'app/armp/services/toast.service';
import {CaptchaService} from '../captcha.service';

@Injectable()
abstract class ResourceResolver<T extends CruService<any>>  {
  protected errorOnNotFound = false;

  constructor(
    protected errorService: ErrorService,
    protected translateService: TranslateService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return new Promise(() => {});
    throw new Error("Method 'resolve()' must be implemented.");
  }

  protected handleNotFound() {
    if (this.errorOnNotFound) {
      this.errorService.handleGenericError(
        this.translateService.instant('ERROR.VOUCHER_NOT_FOUND')
      );
    }
  }

  protected handleError(data: { message?: string; path?: string[] } = {}) {
    this.errorService.handleGenericError(data.message, data.path);
  }
}

@Injectable()
export class CustomerResolver extends ResourceResolver<CustomerService> {
  constructor(
    protected service: CustomerService,
    protected errorService: ErrorService,
    protected translateService: TranslateService
  ) {
    super(errorService, translateService);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.service.getById(+route.params.id || 0).then((entity) => {
      if (!entity) {
        this.handleNotFound();
      }
      return entity;
    });
  }
}

@Injectable()
export class VoucherResolver extends ResourceResolver<VoucherService> {
  protected errorOnNotFound = true;

  constructor(
    protected service: VoucherService,
    protected errorService: ErrorService,
    protected translateService: TranslateService,
    protected authService: AuthService
  ) {
    super(errorService, translateService);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.service.getByCode(route.params.id).then((v) => {
      if (!v) {
        this.handleNotFound();
      } else {
        return v;
      }
    });
  }
}

@Injectable()
export class PublicVoucherResolver extends ResourceResolver<VoucherPublicService> {
  protected errorOnNotFound = true;

  constructor(
    protected service: VoucherPublicService,
    protected errorService: ErrorService,
    protected translateService: TranslateService,
    protected authService: AuthService,
    protected toastService: ToastService,
    private captchaService: CaptchaService
  ) {
    super(errorService, translateService);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise(async (success, reject) => {
      try {
        const header = await this.captchaService.getCaptchaHeader();

        this.service
          .getByCode(route.params.id, header)
          .then((voucher) => {
            if (!voucher) {
              this.toastService.error({
                message: this.translateService.instant(
                  'ERROR.VOUCHER_NOT_FOUND'
                ),
              });
              this.handleError({
                path: ['/public'],
              });
              reject(this.translateService.instant('ERROR.VOUCHER_NOT_FOUND'));
            } else {
              success(voucher);
            }
          })
          .catch((error) => {
            this.handleError({
              path: ['/public'],
            });
            reject(error);
          });
      } catch (error) {
        this.handleError({
          path: ['/public'],
        });
      }
    });
  }
}

@Injectable()
export class LicenseInstanceResolver extends ResourceResolver<LicenseInstanceService> {
  constructor(
    protected service: LicenseInstanceService,
    protected errorService: ErrorService,
    protected translateService: TranslateService
  ) {
    super(errorService, translateService);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.service.getById(+route.params.id || 0).then((entity) => {
      if (!entity) {
        this.handleNotFound();
      }
      return entity;
    });
  }
}
