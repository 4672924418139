import { Component, OnInit } from '@angular/core';

import { SystemService } from 'app/armp/services/system.service';
import { AuthService } from 'app/armp/core/auth.service';

@Component({
    selector: 'app-system-list',
    templateUrl: './system-list.component.html',
    styleUrls: ['./system-list.component.scss'],
    standalone: false
})
export class SystemListComponent implements OnInit {
  dataColumns: any = [];
  searchText: string;

  constructor(
    public systemService: SystemService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataColumns.push({
      name: 'Nome',
      prop: 'name',
      sortable: true,
    });
  }
}
